import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
import { motion } from "framer-motion";
const OurFlowChart = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Flow Chart</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">

        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <div className="prbox ">
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div>
                <h5
                  className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                  style={{ maxWidth: "750px" }}
                >
                  OUR FLOW CHART
                </h5>
                <h5
                  className="text-xl font-bold ml-8 text-white mt-8 "
                  style={{ maxWidth: "750px" }}
                >

                </h5>

              </div>
              <img
                className="place-self-end"
                alt=""
                src="./public/conversionrateoptimizationamico.svg"
              />
            </div>
          </div>

        </motion.div>


        <div className=" mt-4">
          <div className=" w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor  text-center mt-8 "

              >
                INWARD TRANSACTION FLOW
              </h5>
              <div className="grid grid-cols-1 sm:grid-cols-5">
                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">1</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Initiating the Transaction</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">2</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Communication
                          between Banks
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">3</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">ESFB Account
                          Validation</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">4</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Bank's Processing</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">5</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Notification to the
                          Customer</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div>

            </div>
          </div>
        </div>


        <div className=" mt-4">
          <div className=" w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor  text-center mt-8 "

              >
                INITIATING THE TRANSACTION
              </h5>

              <h5
                className="font-bold md:text-xl text-lg ml-8 prtextcolor  text-center mt-8 "

              >
                The inward transaction begins when a customer or
                another entity initiates a payment or transfer to an
                account held at ESFB.
              </h5>

              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor  text-center mt-8 "

              >
                COMMUNICATION BETWEEN
                BANKS
              </h5>

              <h5
                className="font-bold md:text-xl text-lg ml-8 prtextcolor  text-center mt-8 "

              >
                In the case of interbank transactions,
                communication occurs between the sending
                bank (external bank) and ESFB. This may
                involve the use of standardized protocols
                and APIs
              </h5>

              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor  text-center mt-8 "

              >
                ESFB ACCOUNT VALIDATION
              </h5>

              <h5
                className="font-bold md:text-xl text-lg ml-8 prtextcolor  text-center mt-8 "

              >
                ESFB validates the recipient's account
                details and ensures that the funds are
                correctly directed to the intended account.
              </h5>


              <div>

              </div>

            </div>
            <div>

            </div>
          </div>
        </div>


        <div className="prbox my-3">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                BANK'S PROCESSING
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                The payer's bank receives the payment
                instruction and processes the transaction.
                This involves verifying the payer's account
                details, checking for sufficient funds, and
                ensuring compliance with security
                measures
              </h5>

            </div>
            <img
              className="place-self-end"
              style={{ width: "300px" }}
              alt=""
              src="./leafpay/Currency-bro.svg"
            />
          </div>
        </div>


        <div className="prbox my-3">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              style={{ width: "300px" }}
              className="place-self-start"
              alt=""
              src="./leafpay/Paid idea-bro.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                NOTIFICATION TO THE
                CUSTOMER
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                The customer receives a notification,
                either through online banking,
                mobile apps, or other communication
                channels, informing them of the
                successful inward transaction
              </h5>

            </div>

          </div>
        </div>



        {/* outward transaction flow */}
        <div className=" mt-4">
          <div className=" w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor  text-center mt-8 "

              >
                INWARD TRANSACTION FLOW
              </h5>
              <div className="grid grid-cols-1 sm:grid-cols-8">
                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">1</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Initiating the Transaction</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">2</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Transaction
                          Authorization
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">3</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Debiting the Sender's
                          Account
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">4</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Communication with
                          Receiving Bank</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">5</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Funds Transfer</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">6</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Confirmation to
                          the Customer</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">7</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Settlement and
                          Reconciliation</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" text-center py-3 ">
                  <div className="flex justify-center">
                    <div class="flex flex-col items-center p-4 ">
                      <div class="flex items-center justify-center w-12 h-12 rounded-full bg-white text-lg font-bold prtextcolor">8</div>
                      <div class="mt-4 mb-2 w-full bg-white  text-center py-2 px-3">
                        <span class="text-sm font-semibold prtextcolor  px-3">Reporting</span>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <div>

            </div>
          </div>
        </div>


        {/* transaction flow in details */}
        <div className="w-full text-center">
          <div className="grid grid-cols-1  sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">INITIATING THE
              TRANSACTION</div>
            <div className="col-span-2 text-lg text-black">The outward transaction starts when a customer
              initiates a payment or transfer from their account at
              ESFB.</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">TRANSACTION AUTHORIZATION</div>
            <div className="col-span-2 text-lg text-black">The customer may need to authorize the
              transaction using secure methods such as PINs,
              passwords, or biometric authentication.</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">DEBITING THE SENDER'S
              ACCOUNT</div>
            <div className="col-span-2 text-lg text-black">ESFB debits the sender's account for the
              amount of the outward transaction.</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">COMMUNICATION WITH
              RECEIVING BANK
            </div>
            <div className="col-span-2 text-lg text-black">If the transaction is destined for an
              account at another bank, ESFB
              communicates with the receiving bank
              to facilitate the transfer.</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">FUNDS TRANSFER</div>
            <div className="col-span-2 text-lg text-black">ESFB transfers the funds to the recipient's
              account, either within ESFB or to another
              bank, depending on the destination
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">CONFIRMATION TO
              THE CUSTOMER</div>
            <div className="col-span-2 text-lg text-black">The customer receives confirmation of the
              successful outward transaction through
              notifications provided by the bank</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">SETTLEMENT AND
              RECONCILIATION</div>
            <div className="col-span-2 text-lg text-black">Behind the scenes, there is a settlement and
              reconciliation process between ESFB and other
              banks involved to ensure accurate fund transfers.</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 my-4">
            <div className="col-span-1 text-xl prtextcolor">REPORTING</div>
            <div className="col-span-2 text-lg text-black">ESFB may generate reports for both the customer and
              regulatory authorities, providing details of the
              outward transaction
            </div>
          </div>


        </div>

      </div>
    </div>
  );
};

export default OurFlowChart;
