import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillYoutube,
  AiOutlineInstagram,
  AiFillLinkedin
} from "react-icons/ai";
const PrFooter = () => {
  return (
    <div className="footerbox py-3">
      <Helmet>
        <meta charSet="utf-8" />

        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className=" gap-8 ">
        <div className="mx-12">
          <h5 className="text-center text-4xl font-bold my-2">LEAFPAY</h5>
          <h5 className="text-white font-semibold text-base  text-center md:ml-8">
            Phone : +918929326653
          </h5>
          <h5 className="text-white font-semibold text-base  text-center md:ml-8">
            Email : info@leafpay.in
          </h5>
          <h5 className="text-white font-semibold text-base  text-center md:ml-8">
            Website : www.Leafpay.in
          </h5>
          <h5 className="text-white font-semibold text-base  text-center md:ml-8 mt-5">
            A, 6, Budh Vihar Rd, Dharamveer Market, Budh
            Bihar, Block A, Tajpur Pahari Village, Badarpur,
            Delhi, New Delhi, Delhi 110044</h5>
        </div>


      </div>
      <hr className="mt-3" />
      <div className="'text-white text-2xl font-bold text-center py-5">
        © 2023 Leafpay | All Rights Reserved.
      </div>
    </div>
  );
};

export default PrFooter;
