import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
import { motion } from "framer-motion";
const BusinessCorrespondent = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BUSINESS CORRESPONDENT</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <div className="prbox ">
            <div className="grid grid-cols-1 md:grid-cols-2 w-full">
              <div>
                <h5
                  className="md:text-5xl text-2xl font-bold ml-8  mt-8 text-white "
                  style={{ maxWidth: "750px" }}
                >
                  BUSINESS CORRESPONDENT
                </h5>
                <h5
                  className="text-xl font-bold ml-8 text-white mt-8 "
                  style={{ maxWidth: "750px" }}
                >
                  A Business Correspondent (BC) is a person or
                  entity that acts as an intermediary between a
                  financial institution (such as a bank) and the
                  customers, especially in areas where the bank
                  may not have a physical presence or where it
                  may be challenging to reach the target audience
                  directly. Business Correspondents play a crucial
                  role in financial inclusion, bringing banking
                  services to underserved or remote areas.
                </h5>

              </div>
              <img
                className="place-self-end"
                alt=""
                src="./public/designteambro.svg"
              />
            </div>
          </div>
        </motion.div>
        
        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                INVOICE PAYMENT
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Track and manage your invoices,
                receive payments, and get paid
                quickly and securely with our user friendly
                platform. Our invoice
                payments services are designed for
                freelancers, small business owners,
                and large corporations. Safe and
                reliable transactions are ensured with
                advanced security measures
              </h5>

            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/designteamcuate.svg"
            />
          </div>
        </div>

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img className="" alt="" src="./public/createbro.svg" />
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Aadhaar Enabled Payment System
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Aadhaar Enabled Payment System. It is an Indian government
                initiative that allows Aadhaar cardholders to carry out
                financial transactions on a micro-ATM (Automated Teller
                Machine) using their Aadhaar number and fingerprint
                authentication. The system aims to provide basic banking
                services to individuals in rural and remote areas where
                traditional banking infrastructure may be limited
              </h5>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessCorrespondent;
