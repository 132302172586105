import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addNewsLetter } from "../Apis/DataService";
import {
  ToastSuccessNotifications,
  ToastErrorNotifications,
} from "../Notifications/ToastNotifications";

const Prhome = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const add = await addNewsLetter(values);
    if ((add.message = "Success")) {
      ToastSuccessNotifications(
        "You have Successfully Subscribed our NewsLetter"
      );
    } else {
      ToastErrorNotifications("Error,Try again");
    }

    setSubmitting(false);
    resetForm();
  };

  const text = `Our payment acquiring switch offers
  unique capability of handling online as
    well as offline transactions.Also explore
  our in store Acquiring solution that
  enables merchant management`.split(" ");

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>LeafPay </title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto px-2 md:px-0">

        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <div class="flex flex-wrap">
            <div class="w-full sm:w-8/12 mb-10">
              <div class="container mx-auto h-full sm:p-2">
                <nav class="flex px-4 justify-center items-center">
                  <div class="text-center">
                    <img src="./leafpay/leaf.gif" alt="" class="w-22" />
                  </div>
                 
                </nav>
                <header class="container px-4 lg:flex  items-center h-full lg:mt-0">
                  <div class="w-full">
                    <h1 class="text-xl lg:text-4xl font-bold"><span class="text-green-700">LeafPay</span> is a fintech company providing secure and efficient Fintech solutions to businesses of all sizes. Our mission is to help businesses grow with our advanced technology and dedicated team.</h1>
                    <div class="w-20 h-2 bg-green-700 my-4"></div>
                    

                  </div>
                </header>
              </div>
            </div>
            <img src="https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80" alt="Leafs" class="w-full h-min object-cover  sm:w-3/12" />
          </div>

        </motion.div>


       

        <div className="mt-8">
          <h5
            className="md:text-4xl text-2xl my-4 text-center font-medium besley ml-8 text-black mt-8 "

          >
            {text.map((el, i) => (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  duration: 0.25,
                  delay: i / 10
                }}
                key={i}
              >
                {el}{" "}
              </motion.span>
            ))}
          </h5>
        </div>

        <div className=" my-5 mt-24">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                ESCROW SERVICES
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Secure Transactions: Virtual accounts are
                utilized in escrow services for secure
                transactions. The funds are held in a virtual
                account until the conditions of the
                transaction are met.
              </h5>

            </div>
            <img
              className="place-self-end"
              style={{ width: "300px" }}
              alt=""
              src="./leafpay/Business crisis-amico.svg"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              className="place-self-start"
              style={{ width: "300px" }}
              alt=""
              src="./public/mobiletestingbro.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                SUBSCRIPTION SERVICES
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Recurring Payments: Virtual accounts
                are often used for managing recurring
                payments in subscription-based
                services. Each subscriber is assigned a
                unique virtual account for seamless
                billing.
              </h5>

            </div>

          </div>
        </div>

        <div className=" my-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                VENDOR PAYMENTS
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Secure Payments: Virtual accounts provide a
                secure way to make payments to vendors.
                Each vendor is assigned a unique virtual
                account number, reducing the risk of fraud
                and simplifying reconciliation.
              </h5>

            </div>
            <img
              className="place-self-end"
              style={{ width: "300px" }}
              alt=""
              src="./leafpay/Coins-bro.svg"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              className="place-self-start"
              style={{ width: "300px" }}
              alt=""
              src="./public/mobiletestingbro.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                MARKETPLACE TRANSACTIONS
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Seller Payouts: Virtual accounts can be
                assigned to sellers on online
                marketplaces for quick and secure
                payout transactions.
              </h5>

            </div>

          </div>
        </div>

        <div className=" my-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                VISION
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                "To be a global leader in providing secure, seamless, and innovative Fintech solutions, empowering businesses and individuals to thrive in the digital economy."
              </h5>

            </div>
            <img
              className="place-self-end"
              style={{ width: "300px" }}
              alt=""
              src="./leafpay/Imagination-bro.svg"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              className="place-self-start"
              style={{ width: "300px" }}
              alt=""
              src="./leafpay/Business mission-rafiki.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                MISSION
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                "Our mission is to simplify and enhance the User experience by delivering cutting-edge, reliable payment solutions. We are committed to fostering financial inclusivity, promoting trust in transactions, and driving economic growth for our clients and partners worldwide."
              </h5>

            </div>

          </div>
        </div>

        <div className="prbox my-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white  mt-8 "
                style={{ maxWidth: "750px" }}
              >
                OUR SERVICES
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                DIGITAL MERCHANT ONBOARDING
                <br />
                BBPS (BHARAT BILL PAYMENT SYSTEM)
                <br />
                QR SOLUTION (QUICK RESPONSE CODE SOLUTION)
                <br />
                DOMESTIC MONEY TRANSFER
                INVOICE PAYMENT
                IMPS
                RTGS
                NEFT
                <br />
                BUSINESS CORRESPONDENT
                <br />
                INVOICE PAYMENT
                <br />
                AADHAAR ENABLED PAYMENT SYSTEM
              </h5>

            </div>
            <img
              style={{ width: "400px" }}
              className="place-self-end"
              alt=""
              src="./leafpay/Paid idea-bro.svg"
            />
          </div>


        </div>














      </div>
    </div>
  );
};

export default Prhome;
