import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const PrHeader = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/main.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>


      <header>
        <nav className="bg-white border-gray-200 px-4 lg:px-6 py-2.5 ">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <Link to="/">
              <img
                src="./leafpay/logo.jpg"
                className="mr-3 h-28 w-72"
                alt="Flowbite Logo"
              />
            </Link>
            <div className="flex items-center lg:order-2">

              <button
                className="inline-flex p-3 rounded lg:hidden text-gray-500 ml-auto hover:text-gray-900 outline-none"
                onClick={toggleMenu}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                >
                  {showMenu ? (
                    <path d="M6 18L18 6M6 6l12 12"></path>
                  ) : (
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                  )}
                </svg>
              </button>
            </div>
            <nav
              className={`${showMenu ? "flex" : "hidden"
                } lg:flex flex-wrap items-center text-base justify-center`}
            >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li></li>
                <div >
                  <Link to="/business_correspondent" className="prtextcolor font-bold text-base">Business Correspondent</Link>
                </div>

                <div >
                  <Link to="/digital_merchant" className="prtextcolor font-bold text-base">Digital Merchant</Link>
                </div>

                <div >
                  <Link to="/Domestic_money_transfer" className="prtextcolor font-bold text-base">Domestic Money Transfer</Link>
                </div>

                <div >
                  <Link to="/our_expectations" className="prtextcolor font-bold text-base">Expectations</Link>
                </div>

              
                <div className="dropdown">
                  <button className="mx-2 prtextcolor text-base font-bold hover:text-green-500">
                    Info
                  </button>
                  <div className="dropdown-content">
                    <Link to="/privacy_policy"> Privacy Policy</Link>
                    <Link to="/refund_policy">Refund Policy</Link>
                    <Link to="/terms_and_conditions"> Terms and Conditions </Link>
                    <Link to="/contact"> Contact </Link>
                    <Link to="/our_flowchart"> FlowChart </Link>
                  </div>
                </div>


              </ul>
            </nav>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default PrHeader;
