import React from 'react';
import { Helmet } from "react-helmet";
const RefundPolicy = () => {
    return (
        <div>
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Refund Policy</title>
                    <link rel="stylesheet" href="payripecss/global.css" />
                    <link rel="stylesheet" href="payripecss/payripe.css" />
                </Helmet>
                <div className="container mx-auto">
                    <h1 className="text-xl font-bold">Returns & Refunds Policy</h1>

                    <p className="text-lg font-normal my-3">
                        You are entitled to cancel your order within 30 days without giving any reason for doing so.
                    </p>

                    <p className="text-lg font-normal my-3">
                        The deadline for canceling an order is 30 days from the date you received the goods or on which a third party you have appointed, who is not the carrier, takes possession of the product delivered.
                    </p>

                    <p className="text-lg font-normal my-3">
                        In order to exercise your right of cancellation, you must inform us of your decision by means of a clear statement.
                    </p>

                    <p className="text-lg font-normal my-3">
                        You can inform us of your decision by e-mail <a href="mailto:info@leafpay.in">info@leafpay.in</a>.
                    </p>

                    <p className="text-lg font-normal my-3">
                        We will reimburse you no later than 30 days from the day on which we receive the returned goods. We will use the same means of payment as you used for the order, and you will not incur any fees for such reimbursement.
                    </p>

                    <h2 className="text-xl font-bold">Conditions for returns:</h2>

                    <p className="text-lg font-normal my-3">
                        In order for the goods to be eligible for a return, please make sure that:
                    </p>

                    <ul className="text-lg font-normal my-3">
                        <li>The goods were purchased in the last 30 days</li>
                        <li>The goods are in the original packaging</li>
                    </ul>

                    <p className="text-lg font-normal my-3">
                        The following goods cannot be returned:
                    </p>

                    <ul className="text-lg font-normal my-3">
                        <li>The supply of goods made to your specifications or clearly personalized.</li>
                        <li>The supply of goods which according to their nature are not suitable to be returned, for example goods which deteriorate rapidly or where the date of expiry is over.</li>
                        <li>The supply of goods which are not suitable for return due to health protection or hygiene reasons and were unsealed after delivery.</li>
                        <li>The supply of goods which are, after delivery, according to their nature, inseparably mixed with other items.</li>
                    </ul>

                    <p className="text-lg font-normal my-3">
                        We reserve the right to refuse returns of any merchandise that does not meet the above return conditions at our sole discretion.
                    </p>

                    <h2 className="text-xl font-bold">Returning Goods</h2>

                    <p className="text-lg font-normal my-3">
                        You are responsible for the cost and risk of returning the goods to us. You should send the goods to the following address:
                    </p>

                    <p className="text-lg font-normal my-3">
                        A, 6, Budh Vihar Rd, Dharamveer Market, Budh Bihar, Block A, Tajpur Pahari Village, Badarpur, Delhi, New Delhi, Delhi 110044
                    </p>

                    <p className="text-lg font-normal my-3">
                        We cannot be held responsible for goods damaged or lost in return shipment. Therefore, we recommend an insured and trackable mail service. We are unable to issue a refund without actual receipt of the goods or proof of received return delivery.
                    </p>

                    <h2 className="text-xl font-bold">Gifts:</h2>

                    <p className="text-lg font-normal my-3">
                        If the goods were marked as a gift when purchased and then shipped directly to you, you'll receive a gift credit for the value of your return. Once the returned product is received, a gift certificate will be mailed to you.
                    </p>

                    <p className="text-lg font-normal my-3">
                        If the goods weren't marked as a gift when purchased, or the gift giver had the order shipped to themselves to give it to you later, We will send the refund to the gift giver.
                    </p>

                    <h2 className="text-xl font-bold">Contact Us</h2>

                    <p className="text-lg font-normal my-3">
                        If you have any questions about our Returns and Refunds Policy, please contact us by e-mail <a href="mailto:info@leafpay.in">info@leafpay.in</a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RefundPolicy;