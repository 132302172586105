import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
import { motion } from "framer-motion";
const DomesticMoneyTransfer = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Domestic Money Transfer</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">
      <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
        <div className="prbox my-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                DOMESTIC MONEY TRANSFER
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                DMT generally refers to the transfer of
                money within a single country, as
                opposed to international money
                transfers. Domestic money transfers are
                often facilitated by various financial
                institutions, banks, mobile payment
                platforms, and specialized remittance
                services.

              </h5>

            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/messagesamico.svg"
            />
          </div>
        </div>
        </motion.div>

        <div className=" my-4">
          <div className=" w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                
              >
                IMPS
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                
              >
                IMPS stands for Immediate Payment Service,
                and it is an electronic funds transfer system
                in India. It enables users to make instant
                interbank electronic funds transfers through
                various channels such as mobile phones,
                internet banking, ATMs, or through bank
                branches. IMPS is available 24/7, allowing
                users to make transactions at any time.

              </h5>

            </div>
            
          </div>
        </div>

        <div className=" my-4">
          <div className=" w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
                
              >
                RTGS (Real-Time Gross Settlement)
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                
              >
                Nature: RTGS is a real-time funds transfer system where
                transactions are settled individually and immediately.
                Minimum Transaction Amount: RTGS is typically used for highvalue transactions. There is usually a minimum transaction
                amount, which varies by country and bank.
                Timing: RTGS operates on a continuous (24x7) basis. However,
                banks may have specific operating hours for customers.
                Speed: Transactions through RTGS are processed immediately,
                providing real-time settlement.
                Use Cases: RTGS is commonly used for large transactions, such as
                high-value interbank transfers, real estate transactions, and
                corporate payments

              </h5>

            </div>
          </div>
        </div>

        <div className=" my-4">
          <div className=" w-full">
         
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 prtextcolor mt-8 "
               
              >
                NEFT (National Electronic Funds Transfer)
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-black mt-8 "
                
              >
                Nature: NEFT is a funds transfer system that operates on a
                deferred net settlement (DNS) basis. Transactions are
                processed in batches.
                Minimum Transaction Amount: NEFT is suitable for both small
                and large transactions. There is no minimum transaction
                amount.
                Timing: NEFT operates on a half-hourly basis during working
                hours. It is not available on bank holidays and weekends.
                Speed: NEFT transactions are not settled in real-time; they are
                processed in batches, usually at scheduled intervals during
                the day.
                Use Cases: NEFT is commonly used for various transactions,
                including salary payments, small business transactions, and
                retail payments

              </h5>

            </div>
            
          </div>
        </div>

      </div>
    </div>
  );
};

export default DomesticMoneyTransfer;
