import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
import { motion } from "framer-motion";
const DigitalMerchant = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DIGITAL MERCHANT</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">
      <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
        <div className=" ">
          <div className=" w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8  prtextcolor  mt-8 "
                style={{ maxWidth: "750px" }}
              >
                DIGITAL MERCHANT ONBOARDING
              </h5>
              <h5
                className="text-xl font-bold ml-8  text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Digital merchant onboarding refers to the process by which
                merchants, businesses, or service providers register and set up their
                accounts to accept digital payments.
                This process often involves providing necessary documentation, KYC
                (Know Your Customer) information, and integrating with payment
                Process to enable smooth digital transactions.
              </h5>

            </div>
            <div className="text-center my-3">
              <img
                className=""
                alt=""
                src="./leafpay/digitalmerchantonboarding.png"
              />
            </div>

          </div>
        </div>
        </motion.div>

        <div className="prbox my-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                BBPS (BHARAT BILL PAYMENT SYSTEM)
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                BBPS is an integrated bill payment system in
                India that allows users to pay various bills, such
                as electricity, water, gas, and more, through a
                single platform.
                It provides a standardized and interoperable
                platform for payment service providers,
                ensuring a seamless bill payment experience for
                consumers
              </h5>

            </div>
            <img
              className="place-self-end"
              alt=""
              src="./leafpay/Money income-bro.svg"
            />
          </div>
        </div>



        <div className="prbox my-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <img
              className="place-self-end"
              alt=""
              src="./leafpay/Manage money-rafiki.svg"
            />
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                QR SOLUTION (QUICK RESPONSE CODE SOLUTION)
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                A QR solution in the context of digital payments involves the use of QR
                codes for facilitating transactions.
                Merchants generate QR codes that represent payment information, and
                customers can scan these codes using their mobile devices to make
                payments quickly and securely.
                QR solutions are popular for in-store transactions, online purchases, and
                person-to-person payments.
              </h5>

            </div>

          </div>
        </div>




      </div>
    </div>
  );
};

export default DigitalMerchant;
