import React from "react";
import { Helmet } from "react-helmet";
import PrHeader from "../Layout/PrHeader";
import PrFooter from "../Layout/PrFooter";
import { motion } from "framer-motion";


const OurExpectation = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>OUR EXPECTATION</title>
        <link rel="stylesheet" href="payripecss/global.css" />
        <link rel="stylesheet" href="payripecss/payripe.css" />
      </Helmet>
      <div className="container mx-auto">
      <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
        <div className="prbox ">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-2xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                OUR EXPECTATION
              </h5>
              <h5
                className="text-xl font-bold ml-8 text-white mt-8 "
                style={{ maxWidth: "750px" }}
              >
                API (Application Programming Interface) banking refers to the use of
                APIs to enable communication and data exchange between different
                software applications in the banking and financial industry. It
                facilitates the integration of various banking services, systems, and
                applications, allowing for seamless data sharing and transaction
                processing. When it comes to virtual account numbers and API
                banking, there are several ways in which these concepts can be
                associated

              </h5>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/creativewritingamico.svg"
            />
          </div>
        </div>
        </motion.div>
        

        <div className=" mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full">
            <div>
              <h5
                className="md:text-5xl text-xl font-bold ml-8 prtextcolor mt-8 "
                style={{ maxWidth: "750px" }}
              >
                Our Expectations
              </h5>
              <h5
                className="text-base  ml-8 text-black mt-8 "
                style={{ maxWidth: "750px" }}
              >

              </h5>
              <div className="mt-2 ml-8">
                <h5 className="prfactscolor text-base font-semibold flex">

                  Integration with Other
                  Banking Systems
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">

                  Cross-Bank
                  Transactions

                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex ">

                  Account
                  Information
                  Services (AIS)
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">

                  Payment
                  Initiation
                  Services (PIS)
                </h5>
                <h5 className="prfactscolor text-base font-semibold mt-1 flex">

                  Real-Time
                  Transactions
                </h5>
              </div>
            </div>
            <img
              className="place-self-end"
              alt=""
              src="./public/creativewritingrafiki.svg"
            />
          </div>
        </div>

        <div className="my-8">
          <h5 className="text-5xl prtextcolor text-center font-semibold">

          </h5>
          <h5 className="text-2xl text-black text-center font-semibold my-8">

          </h5>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full my-8">

            <div className="prbox text-center py-3 ">
              <div className="flex justify-center">

              </div>
              <h5 className="text-white text-2xl font-bold">Integration with Other Banking Systems</h5>
              <div className="flex justify-center">
                <h5 className="text-white   w-48">
                  Banks implementing API banking may
                  use APIs to integrate virtual account
                  number systems with other banking
                  systems. This integration allows for the
                  exchange of data between different
                  components of the bank's infrastructure.
                </h5>
              </div>
            </div>

            <div className="prbox text-center py-3 ">
              <div className="flex justify-center">

              </div>
              <h5 className="text-white text-2xl font-bold">Cross-Bank Transactions</h5>
              <div className="flex justify-center">
                <h5 className="text-white   w-48">
                  Virtual account numbers, especially when
                  used in the context of API banking, can
                  facilitate cross-bank transactions. APIs
                  enable interoperability between different
                  banks, allowing for the transfer of funds,
                  verification of account details, and other
                  financial transactions.
                </h5>
              </div>
            </div>

            <div className="prbox text-center py-3 ">
              <div className="flex justify-center">

              </div>
              <h5 className="text-white text-2xl font-bold">Account Information
                Services (AIS)</h5>
              <div className="flex justify-center">
                <h5 className="text-white   w-48">
                  Virtual account numbers, especially when
                  used in the context of API banking, can
                  facilitate cross-bank transactions. APIs
                  enable interoperability between different
                  banks, allowing for the transfer of funds,
                  verification of account details, and other
                  financial transactions.
                </h5>
              </div>
            </div>

            <div className="prbox text-center py-3 ">
              <div className="flex justify-center">

              </div>
              <h5 className="text-white text-2xl font-bold">Payment Initiation Services (PIS)</h5>
              <div className="flex justify-center">
                <h5 className="text-white   w-48">
                  · With API banking, banks can offer
                  Payment Initiation Services through APIs.
                  Virtual account numbers may be used to
                  initiate and authorize payments securely,
                  and these transactions can involve
                  accounts in different banks.
                </h5>
              </div>
            </div>

            <div className="prbox text-center py-3 ">
              <div className="flex justify-center">

              </div>
              <h5 className="text-white text-2xl font-bold">Real-Time Transactions</h5>
              <div className="flex justify-center">
                <h5 className="text-white   w-48">
                  API banking allows for real-time
                  transactions, and virtual account
                  numbers can be used to facilitate these
                  transactions swiftly. This is particularly
                  beneficial in scenarios where immediate
                  fund transfers or approvals are required
                </h5>
              </div>
            </div>



          </div>


        </div>
      </div>

    </div>
  );
};

export default OurExpectation;
