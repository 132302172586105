import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Login from "./components/Pages/Login";
import Logout from "./components/Pages/Logout";
import { Provider } from "react-redux";
import store from "./store/store";
import { ProSidebarProvider } from "react-pro-sidebar";
import { ToastContainer, toast } from "react-toastify";
import Home from "./components/User/Home";
import Layout from "./components/sidebar/Layout";
import Dashboard from "./components/Admin/Dashboard";
import NewsCategories from "./components/Admin/NewsCategories";
import Blogs from "./components/Admin/Blogs";
import AddBlog from "./components/Admin/AddBlog";
import Brand from "./components/Admin/Brand";
import Images from "./components/Admin/Images";
import ScrollToTop from "./components/components/ScrollToTop";

import Prlayout from "./components/Payripe/Layout/Prlayout";
import Prhome from "./components/Payripe/PrHome";

import BusinessCorrespondent from "./components/Payripe/Services/BusinessCorrespondent";
import DigitalMerchant from "./components/Payripe/Services/DigitalMerchant";
import DomesticMoneyTransfer from "./components/Payripe/Services/DomesticMoneyTransfer";
import OurExpectation from "./components/Payripe/Services/OurExpectation";
import OurFlowChart from "./components/Payripe/Services/OurFlowChart";
import PrivacyPolicy from "./components/Payripe/Services/PrivacyPolicy";
import TermsConditions from "./components/Payripe/Services/TermsConditions";
import RefundPolicy from "./components/Payripe/Services/RefundPolicy";
import Contact from "./components/Payripe/Services/Contact";

import PrFooter from "./components/Payripe/Layout/PrFooter";

import React from "react";

function App() {
  return (
    <div>
      <ToastContainer />

      <ProSidebarProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Provider store={store}>
            <Routes>
              {/* Admin routes */}
              <Route path="/Login" element={<Login />} />



              <Route element={<Prlayout />}>
                <Route path="/" element={<Prhome />} />


                {/* services */}

                <Route path="/business_correspondent" element={<BusinessCorrespondent />} />
                <Route path="/digital_merchant" element={<DigitalMerchant />} />
                <Route path="/Domestic_money_transfer" element={<DomesticMoneyTransfer />} />
                <Route path="/our_expectations" element={<OurExpectation />} />
                <Route path="/our_flowchart" element={<OurFlowChart />} />

                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                <Route path="/terms_and_conditions" element={<TermsConditions />} />
                <Route path="/refund_policy" element={<RefundPolicy />} />
                <Route path="/contact" element={<Contact />} />


                <Route path="/footer" element={<PrFooter />} />

              </Route>
            </Routes>
          </Provider>
        </BrowserRouter>
      </ProSidebarProvider>
    </div>
  );
}

export default App;
