import React from 'react';
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
        <div>
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Privacy Policy</title>
                    <link rel="stylesheet" href="payripecss/global.css" />
                    <link rel="stylesheet" href="payripecss/payripe.css" />
                </Helmet>
                <div className="container mx-auto">

                    <h1 className='text-xl font-bold'>Privacy Policy</h1>

                    <p className='text-lg font-normal my-3'><em>LeafPay</em> website is owned by LeafPay, which acts as the data controller of your personal information.</p>

                    <p className='text-lg font-normal my-3'>We have established this Privacy Policy to outline the procedures for processing the information collected by LeafPay. It is essential to review this Privacy Policy before utilizing the LeafPay website.</p>

                    <p className='text-lg font-normal my-3'>We prioritize the confidentiality and security of your personal data, taking necessary measures to safeguard it.</p>

                    <h2 className='text-xl font-bold'>Personal Information We Collect:</h2>
                    <p className='text-lg font-normal my-3'>When you visit LeafPay, certain information about your device is automatically collected, including details about your web browser, IP address, time zone, and cookies installed on your device. Additionally, as you navigate the site, we gather information about the specific web pages or products you view, the websites or search terms that referred you to the site, and your interactions with the site. This automatically collected information is referred to as "Device Information." Moreover, we may collect personal data you provide during registration, such as Name, Surname, Address, payment information, etc., necessary to fulfill the agreement.</p>

                    <h2 className='text-xl font-bold'>Why We Process Your Data:</h2>
                    <p className='text-lg font-normal my-3'>Our primary focus is customer data security. We process minimal user data, only as much as necessary to maintain the website. Automatically collected information is utilized to identify potential abuse cases and generate statistical insights into website usage. This statistical information is not aggregated in a way that identifies specific users.</p>

                    <p className='text-lg font-normal my-3'>You can visit the website without disclosing personal information. However, if you wish to use specific features or receive newsletters, providing personal data, such as email, first name, last name, city of residence, organization, telephone number, may be required. Users uncertain about mandatory information can contact us via <a href="mailto:info@leafpay.in">info@leafpay.in</a>.</p>

                    <h2>Your Rights:</h2>
                    <p className='text-lg font-normal my-3'>If you are a European resident, you have the following rights related to your personal data:</p>
                    <ul>
                        <li className='text-base my-2 font-normal'>The right to be informed.</li>
                        <li className='text-base my-2 font-normal'>The right of access.</li>
                        <li className='text-base my-2 font-normal'>The right to rectification.</li>
                        <li className='text-base my-2 font-normal'>The right to erasure.</li>
                        <li className='text-base my-2 font-normal'>The right to restrict processing.</li>
                        <li className='text-base my-2 font-normal'>The right to data portability.</li>
                        <li className='text-base my-2 font-normal'>The right to object.</li>
                        <li className='text-base my-2 font-normal'>Rights in relation to automated decision-making and profiling.</li>
                    </ul>

                    <p className='text-lg font-normal my-3'>To exercise these rights, contact us through the provided contact information. Additionally, note that we process information to fulfill contracts or pursue legitimate business interests. Your information may be transferred outside of Europe, including Canada and the United States.</p>

                    <h2 className='text-xl font-bold'>Links to Other Websites:</h2>
                    <p className='text-lg font-normal my-3'>Our website may contain links to other websites not owned or controlled by us. We are not responsible for their privacy practices, so please review their privacy statements when leaving our site.</p>

                    <h2 className='text-xl font-bold'>Information Security:</h2>
                    <p className='text-lg font-normal my-3'>We secure your provided information on computer servers with appropriate safeguards. However, no data transmission over the Internet or wireless network can be guaranteed.</p>

                    <h2 className='text-xl font-bold'>Legal Disclosure:</h2>
                    <p className='text-lg font-normal my-3'>We will disclose collected information if required or permitted by law to protect rights, safety, investigate fraud, or respond to a government request.</p>

                    <h2 className='text-xl font-bold'>Contact Information:</h2>
                    <p className='text-lg font-normal my-3'>For further inquiries about this Policy or any matter related to individual rights and Personal Information, email us at <a href="mailto:info@leafpay.in">info@leafpay.in</a>.</p>

                   
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;