import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PrHeader from "./PrHeader";
import PrFooter from "./PrFooter";
import { useDispatch, useSelector } from "react-redux";
const Prlayout = () => {
  return (
    <div className="container mx-auto">
      <body className="flex flex-col h-screen">
        <header className=" text-white ">
          {" "}
          <PrHeader />
        </header>

        <main className="flex-1 my-3">
          <div className="container mx-auto px-2 md:px-4">
          <Outlet />
          </div>
          
        </main>

        <footer className=" text-white ">
          <PrFooter />
        </footer>
      </body>
    </div>
  );
};

export default Prlayout;
